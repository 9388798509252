import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store'

import Login from '@/components/MyLogin'

import Home from '@/components/MyHome'
import Users from '@/components/Users/ListUsers'
import Numeros from '@/components/Numeros/ListNumeros'
import Multas from '@/components/Multas/ListMultas'
import MultasJogadores from '@/components/MultasJogadores/ListMultasJogadores'
import PagamentosJogadores from '@/components/MultasJogadores/ListPagamentosJogadores'
import CalendarioUser from '@/components/Calendarios/ListCalendarioUser'
import CalendarioUsers from '@/components/Calendarios/ListCalendarioUsers'
import UltimasMultasUser from '@/components/Calendarios/ListUltimasMultasUser'



Vue.use(Router)

export let router = new Router({
  routes: [
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        auth: true,
      }
    },
    {
      path: '/',
      name: 'empty-path',
      component: Home,
      meta: {
        auth: true,
      }
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        meta: {
          auth: true,
        }
    },
    {
      path: '/numeros',
      name: 'numeros',
      component: Numeros,
      meta: {
        auth: true,
      }
    },
    {
      path: '/multas',
      name: 'multas',
      component: Multas,
      meta: {
        auth: true,
      }
    },
    {
      path: '/multasusers',
      name: 'multasusers',
      component: MultasJogadores,
      meta: {
        auth: true,
      }
    },
    {
      path: '/pagamentosusers',
      name: 'pagamentosusers',
      component: PagamentosJogadores,
      meta: {
        auth: true,
      }
    },
    {
      path: '/calendariouser',
      name: 'calendariouser',
      component: CalendarioUser,
      meta: {
        auth: true,
      }
    },
    {
      path: '/calendariousers',
      name: 'calendariousers',
      component: CalendarioUsers,
      meta: {
        auth: true,
      }
    },
    {
      path: '/ultimasmultasuser',
      name: 'ultimasmultasuser',
      component: UltimasMultasUser,
      meta: {
        auth: true,
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !store.state.isUserLoggedIn) {
    next({
      path: '/login',
      query: {
        redirect: to.fullPath
      }
    });
  } else {
    if (to.meta.auth && store.state.logTime == null) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      if (to.meta.auth && (Date.now() - store.state.logTime >= 43200000)) {
        next({
          path: '/login',
          query: {
            redirect: to.fullPath
          }
        });
      } else {
        next();
      }
    }
  }
})