<template>
  <v-card height="100%">
    <v-speed-dial v-if="$store.state.user.role === 'admin'" fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
        <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark large fab>
                <v-icon v-if="fab">mdi-close</v-icon>
                <v-icon v-else>mdi-filter-variant</v-icon>
            </v-btn>
        </template>
        <v-btn fab dark large color="green" @click="dialog = true">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
    </v-speed-dial>
    <template v-if="$store.state.user.role === 'admin'">
      <div class="text-center">
          <b><p>Multas jogadores</p></b>
      </div>
      <v-card class="mb-1">
          <template>
              <v-text-field v-model="search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined" hide-details single-line ></v-text-field>
          </template>
      </v-card>
      <v-data-table :search="search" :loading="loading" hide-default-footer :headers="headers" :items="multasjogadores" class="elevation-1" :items-per-page="-1">
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="1500px">
                <v-card>
                    <v-tabs v-model="tab" flat dark background-color="primary">
                        <v-tab href="#tab-1">{{ formTitle }}</v-tab>
                        <v-tabs-items v-model="tab">
                            <v-tab-item value="tab-1">
                                <v-card-text>
                                  <v-form ref="form" v-model="valid" lazy-validation>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12">
                                              <v-dialog ref="dialog" v-model="modal" :return-value.sync="editedItem.start" persistent width="290px">
                                                  <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-model="editedItem.start" :rules="[rules.required]" label="Dia da Multa" v-bind="attrs" v-on="on"></v-text-field>
                                                  </template>
                                                  <v-date-picker v-model="editedItem.start" scrollable locale="pt">
                                                      <v-spacer></v-spacer>
                                                      <v-btn text color="primary" @click="modal = false">
                                                        Cancel
                                                      </v-btn>
                                                      <v-btn text color="primary" @click="$refs.dialog.save(editedItem.start)">
                                                        OK
                                                      </v-btn>
                                                  </v-date-picker>
                                              </v-dialog>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-select v-model="editedItem.userIds" :items="users" multiple item-text="name" item-value="id" :rules="[rules.required]" label="Nome do jogador"></v-select>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-select v-model="editedItem.multaId" :items="multas" item-text="descricao" item-value="id" :rules="[rules.required]" label="Multa"></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                  </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn color="primary" @click="close">
                                        Cancelar
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" :disabled="!valid" @click="save">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-tabs>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="1500px">
                <v-card>
                  <v-tabs v-model="tab" flat dark background-color="primary">
                      <v-tab href="#tab-1">Apagar número</v-tab>
                      <v-tabs-items v-model="tab">
                          <v-tab-item value="tab-1">
                              <v-card-text>
                                  <v-container>
                                      <v-row>
                                          <v-col cols="12">
                                            <v-card-title class="text-h5">Quer mesmo eliminar a multa do jogador?</v-card-title>
                                          </v-col>
                                      </v-row>
                                  </v-container>
                              </v-card-text>
                              <v-card-actions>
                                  <v-btn color="primary" @click="closeDelete">
                                      Cancelar
                                  </v-btn>
                                  <v-spacer></v-spacer>
                                  <v-btn color="primary" @click="deleteItemConfirm">
                                      OK
                                  </v-btn>
                              </v-card-actions>
                          </v-tab-item>
                      </v-tabs-items>
                  </v-tabs>                         
                </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.name`]="{ item }">
              {{ item.user?.name }}
          </template>
          <template v-slot:[`item.descricao`]="{ item }">
              {{ item.multa?.descricao }}
          </template>
          <template v-slot:[`item.valor`]="{ item }">
              {{ item.multa?.valor }} €
          </template>
          <template v-slot:[`item.dia`]="{ item }">
              {{ formatDate(item.start) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
              <v-icon big class="mr-2" @click="editItem(item)" :color="'green'">
                  mdi-pencil
              </v-icon>
              <v-icon v-if="item.numero != '0'" big @click="deleteItem(item)" :color="'red'">
                  mdi-delete
              </v-icon>
          </template>
      </v-data-table>
    </template>
    <template v-else>
      <v-card-text>
          <h3 class="headline mb-0">Não tem premissões para aceder a esta página!!!</h3>
      </v-card-text>
    </template>
  </v-card>
</template>
  
<script>
import ServiceMultasJogadores from '@/services/ServiceMultasJogadores'
import ServiceUsers from '@/services/ServiceUsers'
import ServiceMultas from '@/services/ServiceMultas'
export default {
  name: "ListMultasJogadores",
  data: () => ({
      modal: false,
      dialog: false,
      dialogDelete: false,
      loading: true,
      tab: null,
      headers: [
        { text: 'Jogador', value: 'name', align: 'start'},
        { text: 'Multa', value: 'descricao' },
        { text: 'Valor (€)', value: 'valor'},
        { text: 'Dia da Multa', value: 'dia'},
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      valid: true,
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      },
      fab: false,
      search: '',
      multasjogadores: [],
      users: [],
      multas: [],
      editedIndex: -1,
      editedItem: {
        start: '',
        end: '',
        multaId: '',
        userIds: [],
      },
      defaultItem: {
        start: '',
        end: '',
        multaId: '',
        userIds: [],
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Criar multa jogador' : 'Editar multa jogador'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        if (this.$store.state.user.role === 'admin') {
          this.loading = false;
          this.multasjogadores = (await ServiceMultasJogadores.findAll()).data
          this.users = (await ServiceUsers.findAllContinued()).data
          this.multas = (await ServiceMultas.findAllContinued()).data
        } else {
          this.$router.push({
            name: "home"
          });     
        } 
      },

      formatDate (date) {
          const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
          return new Date(date).toLocaleDateString('pt-PT', options)
      },

      editItem (item) {
        this.editedIndex = this.multasjogadores.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.multasjogadores.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        try {
          /*Apagar*/
          this.$store.dispatch("setSnackbar", {
            color: "green",
            text: "Apagado com sucesso."
          });
          this.multasjogadores.splice(this.editedIndex, 1)
          this.closeDelete()  
          await ServiceMultasJogadores.delete(this.editedItem)
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "red",
            text: "Erro a apagar."
          });
        }
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
        try{
          if (this.$refs.form.validate()) {
            this.editedItem.end = this.editedItem.start
            if (this.editedIndex > -1) {
              try {
                /*Editar*/
                this.$store.dispatch("setSnackbar", {
                  color: "green",
                  text: "Editado com sucesso."
                });
                Object.assign(this.multasjogadores[this.editedIndex], this.editedItem)
                await ServiceMultasJogadores.update(this.editedItem)
              } catch (error) {
                this.$store.dispatch("setSnackbar", {
                  color: "red",
                  text: "Erro a editar."
                });
              }
            } else {
              // Creating new fines for multiple users
              for (const userId of this.editedItem.userIds) {
                const newItem = { ...this.editedItem, userId };
                try {
                  this.$store.dispatch("setSnackbar", {
                    color: "green",
                    text: "Criado com sucesso."
                  });
                  this.multasjogadores.push(newItem);
                  await ServiceMultasJogadores.create(newItem);
                } catch (error) {
                  this.$store.dispatch("setSnackbar", {
                    color: "red",
                    text: "Erro a criar."
                  });
                }
              }
            }
            await this.initialize();
            this.close()
          }
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "red",
            text: error
          });
        }
      }
    }
};
</script>

<style>
</style>