import Api from '@/services/api'

export default {
  findAll() {
    return Api().get('/numeros');
  },

  create (item) {
    return Api().post(`numeros`, item)
  },

  update (item) {
    return Api().put(`numeros/${item.id}`, item)
  },

  delete (item) {
    return Api().delete(`numeros/${item.id}`, item)
  },

}