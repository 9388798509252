<template>
    <v-app id="inspire">
        <v-navigation-drawer id="menulateral" :mini-variant.sync="mini" :mini="mini" v-model="drawer" app v-if="$store.state.isUserLoggedIn">
            <v-list dense nav>
                <v-list-item link><v-list-item-icon><v-icon></v-icon></v-list-item-icon><v-list-item-title></v-list-item-title></v-list-item>
                
                <v-divider class="pt-5"></v-divider>
                <v-list-item link :to="{name: 'home'}"><v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon><v-list-item-title>Ínicio</v-list-item-title></v-list-item>
                <v-list-item link :to="{name: 'ultimasmultasuser'}"><v-list-item-icon><v-icon>mdi-list-status</v-icon></v-list-item-icon><v-list-item-title>Últimas Multas</v-list-item-title></v-list-item>
                <v-list-item link :to="{name: 'calendariouser'}"><v-list-item-icon><v-icon>mdi-calendar-month-outline</v-icon></v-list-item-icon><v-list-item-title>Multas Mês</v-list-item-title></v-list-item>
                <v-list-item link :to="{name: 'calendariousers'}"><v-list-item-icon><v-icon>mdi-table-eye</v-icon></v-list-item-icon><v-list-item-title>Multas de todos</v-list-item-title></v-list-item>
                <v-divider v-if="$store.state.user.role === 'admin'" class="mDivider"></v-divider>
                <v-list-item v-if="$store.state.user.role === 'admin'" link :to="{name: 'multasusers'}"><v-list-item-icon><v-icon>mdi-view-list-outline</v-icon></v-list-item-icon><v-list-item-title>Multas jogadores</v-list-item-title></v-list-item>
                <v-list-item v-if="$store.state.user.role === 'admin'" link :to="{name: 'pagamentosusers'}"><v-list-item-icon><v-icon>mdi-view-list-outline</v-icon></v-list-item-icon><v-list-item-title>Pagamentos jogadores</v-list-item-title></v-list-item>
                <v-divider class="mDivider"></v-divider>
                <v-list-item v-if="$store.state.user.role === 'admin'" link :to="{name: 'users'}"><v-list-item-icon><v-icon>mdi-account-multiple</v-icon></v-list-item-icon><v-list-item-title>Jogadores</v-list-item-title></v-list-item>
                <v-list-item v-if="$store.state.user.role === 'admin'" link :to="{name: 'numeros'}"><v-list-item-icon><v-icon>mdi-format-list-numbered</v-icon></v-list-item-icon><v-list-item-title>Numeros</v-list-item-title></v-list-item>
                <v-list-item link :to="{name: 'multas'}"><v-list-item-icon><v-icon>mdi-format-list-checkbox</v-icon></v-list-item-icon><v-list-item-title>Multas</v-list-item-title></v-list-item>
                <v-list-item v-if="$store.state.user.role !== 'admin'" link :to="{name: 'pagamentosusers'}"><v-list-item-icon><v-icon>mdi-view-list-outline</v-icon></v-list-item-icon><v-list-item-title>Pagamentos</v-list-item-title></v-list-item>
                <v-divider class="mDivider"></v-divider>
                <v-list-item link @click="logout()"><v-list-item-icon><v-icon>mdi-logout-variant</v-icon></v-list-item-icon><v-list-item-title>Logout</v-list-item-title></v-list-item>
            </v-list>  
            

            <template v-slot:append>    
              <v-list-item class="hidden-md-and-down" @click.stop="mini = !mini" style="justify-content: end">
                <v-icon color="white" v-if="mini">mdi-chevron-right</v-icon>
                <v-icon color="white" v-else>mdi-chevron-left</v-icon>
              </v-list-item>
            </template>
            
        </v-navigation-drawer>

        <v-app-bar app class="elevation-0" style="background-color: #ffffff">
            <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
                <v-app-bar-nav-icon v-if="$store.state.isUserLoggedIn" @click.stop="drawer = !drawer" class="hidden-lg-and-up">
                    <v-icon>mdi-menu</v-icon>
                </v-app-bar-nav-icon>
                <span class="hidden-sm-and-down" ref="mainTitle">Multas UD Serra</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon large class="mr-5">
                <v-avatar size="50px" tile>
                  <img src="../public/logo_uds.png" alt="Logotipo UD Serra" />
                </v-avatar>
            </v-btn>
        </v-app-bar>
        
        

        <v-main ref="mainContainer" style="background-color: #f5f5ff;">
            <router-view :key="$route.fullPath"></router-view>
            <v-snackbar v-model="snackbar.showing" :timeout="6000" :color="snackbar.color">{{ snackbar.text }}
                <template v-slot:action="{ attrs }">
                  <v-btn color="white" text v-bind="attrs" @click="snackbar.showing = false">
                      Close
                  </v-btn>
                </template>
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
      drawer: null,
      mini: null,
  }),
  methods: {
    logout() {
      try {
        this.$store.dispatch("setToken", null);
        this.$store.dispatch("setUser", null);
        this.$store.dispatch("setLogTime", null);
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error.message
        });
      }
    }
  },
  computed: {
    ...mapState(["snackbar", "user", 'isUserLoggedIn', 'title'])
  },
  watch: {
    isUserLoggedIn(newValue) {
      if (!newValue) {
        this.$router.push({
          name: "login"
        });
      }
    }
  }, beforeCreate() {
    this.$store.dispatch("setSnackbar", {
      showing: false,
      color: "error",
      text: "fdsfsdf"
    });
  },
  props: {
    source: String
  }
};
</script>

<style>
/*geral*/
.Tred{
    color: red;
}
.Tgreen{
    color: green;
}
.mDivider {
  border-width: thin 0 thin 0 !important;
  border-color: white !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
#menulateral {
  background-color: rgb(13, 33, 73) !important;
}
#menulateral .v-list-item__title, #menulateral .v-list-item__icon .v-icon{
  color: white !important;
}
.totaldia{
  font-size: 17px !important;
}

/*tabelas*/
.text-start{
  font-size: 17px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr:nth-of-type(2n) {
    background-color: rgba(75,255,0,.1);
}

.v-data-table > .v-data-table__wrapper > table > thead {
  background-color: #1976D2 !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  color: white !important;
}
.v-data-table-header-mobile__wrapper {
  display: none !important;
}
</style>