import Api from '@/services/api'

export default {
  login(userName,pass) {
    return Api().get(`users/login`,{
        params: {
          userName: userName,
          password: pass
        }
      })
  },
  findAll() {
    return Api().get('/users');
  },

  findAllUser (userid) {
    return Api().get(`users/user`, {
      params: {
        userId: userid,
      }
    })
  },

  findById() {
    return Api().get('/users');
  },

  findAllNaoAdmin() {
    return Api().get('/users/naoadmin');
  },
  
  create (item) {
    return Api().post(`users`, item)
  },

  update (item) {
    return Api().put(`users/${item.id}`, item)
  },

  delete (item) {
    return Api().delete(`users/${item.id}`, item)
  },

  findAllContinued() {
    return Api().get('/userscontinued');
  },

  changePassword(id,pass) {
    return Api().post(`users/changepassword`, {
      params: {
        idutilizador: id,
        passnova: pass
      }
    })
  },
}