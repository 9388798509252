import axios from "axios";
import store from '@/store/store'

export default () => {
    return axios.create({
      baseURL: 'https://senioresmultas.udserra.pt/api/',
      headers: {
        Authorization: `Bearer ${store.state.token}`
      }
    })
  }