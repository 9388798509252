<template>
  <v-card height="100%">
    <v-speed-dial v-if="$store.state.user.role === 'admin'" fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
        <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark large fab>
                <v-icon v-if="fab">mdi-close</v-icon>
                <v-icon v-else>mdi-filter-variant</v-icon>
            </v-btn>
        </template>
        <v-btn fab dark large color="green" @click="dialog = true">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
    </v-speed-dial>
    <template v-if="$store.state.user.role === 'admin'">
      <div class="text-center">
          <b><p>Números</p></b>
      </div>
      <v-card class="mb-1">
          <template>
              <v-text-field v-model="search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined" hide-details single-line ></v-text-field>
          </template>
      </v-card>
      <v-data-table :search="search" :loading="loading" hide-default-footer :headers="headers" :items="numeros" class="elevation-1" :items-per-page="-1">
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="1500px">
                <v-card>
                    <v-tabs v-model="tab" flat dark background-color="primary">
                        <v-tab href="#tab-1">{{ formTitle }}</v-tab>
                        <v-tabs-items v-model="tab">
                            <v-tab-item value="tab-1">
                                <v-card-text>
                                  <v-form ref="form" v-model="valid" lazy-validation>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field v-model="editedItem.numero" type="number" :rules="[rules.required, rules.isValidNumber]" label="Número"></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field v-model="editedItem.valor" type="number" :rules="[rules.required, rules.isValidNumber]" label="Valor"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                  </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn color="primary" @click="close">
                                        Cancelar
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" :disabled="!valid" @click="save">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-tabs>
                    
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="1500px">
                <v-card>
                  <v-tabs v-model="tab" flat dark background-color="primary">
                      <v-tab href="#tab-1">Apagar número</v-tab>
                      <v-tabs-items v-model="tab">
                          <v-tab-item value="tab-1">
                              <v-card-text>
                                  <v-container>
                                      <v-row>
                                          <v-col cols="12">
                                            <v-card-title class="text-h5">Quer mesmo eliminar o número?</v-card-title>
                                          </v-col>
                                      </v-row>
                                  </v-container>
                              </v-card-text>
                              <v-card-actions>
                                  <v-btn color="primary" @click="closeDelete">
                                      Cancelar
                                  </v-btn>
                                  <v-spacer></v-spacer>
                                  <v-btn color="primary" @click="deleteItemConfirm">
                                      OK
                                  </v-btn>
                              </v-card-actions>
                          </v-tab-item>
                      </v-tabs-items>
                  </v-tabs>                         
                </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.valor`]="{ item }">
              {{ item.valor }} €
          </template>
          <template v-slot:[`item.actions`]="{ item }">
              <v-icon big class="mr-2" @click="editItem(item)" :color="'green'">
                  mdi-pencil
              </v-icon>
              <v-icon v-if="item.numero != '0'" big @click="deleteItem(item)" :color="'red'">
                  mdi-delete
              </v-icon>
          </template>
      </v-data-table>
    </template>
    <template v-else>
      <v-card-text>
          <h3 class="headline mb-0">Não tem premissões para aceder a esta página!!!</h3>
      </v-card-text>
    </template>
  </v-card>
</template>
  
<script>
import ServiceNumeros from '@/services/ServiceNumeros'
export default {
  name: "ListNumeros",
  data: () => ({
      dialog: false,
      dialogDelete: false,
      loading: true,
      tab: null,
      search: '',
      headers: [
        { text: 'Número do Jogador', value: 'numero'},
        { text: 'Valor (€)', value: 'valor'},
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      valid: true,
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      },
      fab: false,
      numeros: [],
      editedIndex: -1,
      editedItem: {
        numero: '',
        valor: '0.00'
      },
      defaultItem: {
        numero: '',
        valor: '0.00'
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Criar número' : 'Editar número'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        if (this.$store.state.user.role === 'admin') {
            this.loading = false;
            this.numeros = (await ServiceNumeros.findAll()).data
        } else {
            this.$router.push({
                name: "home"
            });     
        }        
      },

      editItem (item) {
        this.editedIndex = this.numeros.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.numeros.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        try {
          /*Apagar*/
          this.$store.dispatch("setSnackbar", {
            color: "green",
            text: "Apagado com sucesso."
          });
          this.numeros.splice(this.editedIndex, 1)
          this.closeDelete()  
          await ServiceNumeros.delete(this.editedItem)
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "red",
            text: "Erro a apagar."
          });
        }
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
        try{
          if (this.$refs.form.validate()) {
            if (this.editedIndex > -1) {
              try {
                /*Editar*/
                this.$store.dispatch("setSnackbar", {
                  color: "green",
                  text: "Editado com sucesso."
                });
                Object.assign(this.numeros[this.editedIndex], this.editedItem)
                await ServiceNumeros.update(this.editedItem)
              } catch (error) {
                this.$store.dispatch("setSnackbar", {
                  color: "red",
                  text: "Erro a editar."
                });
              }
            } else {
              try {
                /*Criar*/
                this.$store.dispatch("setSnackbar", {
                  color: "green",
                  text: "Criado com sucesso."
                });
                this.numeros.push(this.editedItem)
                await ServiceNumeros.create(this.editedItem)
              } catch (error) {
                this.$store.dispatch("setSnackbar", {
                  color: "red",
                  text: "Erro a criar."
                });
              }
            }
            this.close()
          }
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "red",
            text: error
          });
        }
      }
    }
};
</script>

<style>
</style>