<template>
    <v-container fill-height fluid v-if="!$store.state.isUserLoggedIn">
        <v-row justify="center">
            <v-col cols="12" md="8">
                <v-card>
                    <v-toolbar dark color="primary" class="elevation-0">
                        <v-toolbar-title>Autenticação</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-form @keyup.native.enter="doLogin()">
                        <v-text-field prepend-icon="mdi-account" name="login" label="Utilizador" type="text" v-model="userName">
                        </v-text-field>
                        <v-text-field id="password" prepend-icon="mdi-lock" name="password" label="Password" type="password"
                            v-model="password"></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="doLogin()">Login</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import ServiceUsers from "@/services/ServiceUsers";
export default {
  name: "MyLogin",
  data() {
    return {
      userName: "",
      password: "",
      errors: []
    };
  },
  methods: {
    async doLogin() {
      try {
        const response = await ServiceUsers.login(this.userName, this.password);
        
        this.$store.dispatch("setToken", response.data.token);
        this.$store.dispatch("setUser", response.data.users);
        this.$store.dispatch("setLogTime", response.data.logTime);

        this.$router.push(this.$route.query.redirect || "home");

      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "red",
          text: "Login inválido."
        });
      }
    }
  }
};
</script>

<style>
</style>