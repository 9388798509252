<template>
  <v-card height="100%">
    <v-speed-dial v-if="$store.state.user.role === 'admin'" fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
        <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark large fab>
                <v-icon v-if="fab">mdi-close</v-icon>
                <v-icon v-else>mdi-filter-variant</v-icon>
            </v-btn>
        </template>
        <v-btn fab dark large color="green" @click="dialog = true">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
    </v-speed-dial>
    <template v-if="$store.state.user.role === 'admin'">
      <div class="text-center">
          <b><p>Jogadores</p></b>
      </div>
      <v-card class="mb-1">
          <template>
              <v-text-field v-model="search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined" hide-details single-line ></v-text-field>
          </template>
      </v-card>
      <v-data-table :search="search" :loading="loading" hide-default-footer :headers="headers" :items="users" class="elevation-1" :items-per-page="-1">
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="1500px">
                <v-card>
                    <v-tabs v-model="tab" flat dark background-color="primary">
                        <v-tab href="#tab-1" v-if="editedItem.role != 'admin'">{{ formTitle }}</v-tab>
                        <v-tab href="#tab-2" v-if="editedIndex != -1">Modificar password</v-tab>
                        <v-tabs-items v-model="tab">
                            <v-tab-item value="tab-1" v-if="editedItem.role != 'admin'">
                                <v-card-text>
                                  <v-form ref="form" v-model="valid" lazy-validation>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12">
                                                Estado
                                                <v-switch color="green" v-model="editedItem.discontinued"></v-switch>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field v-model="editedItem.userName" :rules="[rules.required]" label="Login"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" v-if="editedIndex == -1">
                                                <v-text-field v-model="editedItem.password" :rules="[rules.required]" label="Password" type="password"></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field v-model="editedItem.name" :rules="[rules.required]" label="Nome"></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                              <v-dialog ref="dialog" v-model="modal" :return-value.sync="editedItem.aniversario" persistent width="290px">
                                                  <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-model="editedItem.aniversario" :rules="[rules.required]" label="Aniversário" v-bind="attrs" v-on="on"></v-text-field>
                                                  </template>
                                                  <v-date-picker v-model="editedItem.aniversario" scrollable locale="pt">
                                                      <v-btn text color="primary" @click="modal = false">
                                                        Cancel
                                                      </v-btn>
                                                      <v-spacer></v-spacer>
                                                      <v-btn text color="primary" @click="$refs.dialog.save(editedItem.aniversario)">
                                                        OK
                                                      </v-btn>
                                                  </v-date-picker>
                                              </v-dialog>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-select v-model="editedItem.role" :items="roles" item-text="text" item-value="value" :rules="[rules.required]" label="Nível de permissão"></v-select>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-select v-model="editedItem.numeroId" :items="numeros" item-text="numero" item-value="id" :rules="[rules.required]" label="Número do jogador"></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                  </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn color="primary" @click="close">
                                        Cancelar
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" :disabled="!valid" @click="save">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-tab-item>
                            <v-tab-item value="tab-2" v-if="editedIndex != -1">
                                <v-card-text>
                                      <v-container>
                                          <v-row>
                                              <v-col cols="12">
                                                  <v-text-field v-model="passwordNova" label="Password" type="password"></v-text-field>
                                              </v-col>
                                              <v-col cols="12">
                                                  <v-text-field v-model="confirmPassNova" label="Repetir password" type="password"></v-text-field>
                                              </v-col>
                                          </v-row>
                                      </v-container>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="close">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="primary" @click="savepassword(editedItem.id)">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-tabs>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="1500px">
                <v-card>
                  <v-tabs v-model="tab" flat dark background-color="primary">
                      <v-tab href="#tab-1">Apagar Utilizador</v-tab>
                      <v-tabs-items v-model="tab">
                          <v-tab-item value="tab-1">
                              <v-card-text>
                                  <v-container>
                                      <v-row>
                                          <v-col cols="12">
                                            <v-card-title class="text-h5">Quer mesmo eliminar o Utilizador?</v-card-title>
                                          </v-col>
                                      </v-row>
                                  </v-container>
                              </v-card-text>
                              <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="primary" @click="closeDelete">
                                      Cancelar
                                  </v-btn>
                                  <v-btn color="primary" @click="deleteItemConfirm">
                                      OK
                                  </v-btn>
                              </v-card-actions>
                          </v-tab-item>
                      </v-tabs-items>
                  </v-tabs>                         
                </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.numero`]="{ item }">
            {{ item.numero?.numero }}
          </template>
          <template v-slot:[`item.aniversario`]="{ item }">
            {{ formatDate(item.aniversario) }}
          </template>
          <template v-slot:[`item.discontinued`]="{ item }">
              <template v-if="item.discontinued != true">         
                  <v-icon class="botao_estado_desativo">mdi mdi-toggle-switch-off</v-icon>
              </template>
              <template v-else>
                  <v-icon class="botao_estado_ativo">mdi mdi-toggle-switch</v-icon>
              </template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
              <v-icon big class="mr-2" @click="editItem(item)" :color="'green'">
                  mdi-pencil
              </v-icon>
              <v-icon v-if="item.role != 'admin'" big @click="deleteItem(item)" :color="'red'">
                  mdi-delete
              </v-icon>
          </template>
      </v-data-table>
    </template>
    <template v-else>
      <v-card-text>
          <h3 class="headline mb-0">Não tem premissões para aceder a esta página!!!</h3>
      </v-card-text>
    </template>
  </v-card>
</template>
  
<script>
import ServiceUsers from '@/services/ServiceUsers'
import ServiceNumeros from '@/services/ServiceNumeros'
export default {
  name: "ListUsers",
  data: () => ({
      modal: false,
      dialog: false,
      dialogDelete: false,
      loading: true,
      tab: null,
      search: '',
      roles: [
        { text: "Administrador", value: "admin" },
        { text: "Jogador", value: "player" }
      ],
      headers: [
        { text: 'Imagem', value: '', align: 'start'},
        { text: 'Número do Jogador', value: 'numero'},
        { text: 'Login', value: 'userName'},
        { text: 'Nome', value: 'name'},
        { text: 'Aniversário', value: 'aniversario'},
        { text: 'Estado', value: 'discontinued'},
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      valid: true,
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      },
      fab: false,
      passwordNova: "",
      confirmPassNova: "",
      users: [],
      numeros: [],
      editedIndex: -1,
      editedItem: {
        userName: '',
        name: '',
        aniversario: '',
        discontinued: 1,
        role: 'player',
        numeroId: ''
      },
      defaultItem: {
        userName: '',
        name: '',
        aniversario: '',
        discontinued: 1,
        role: 'player',
        numeroId: ''
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Criar jogador' : 'Editar jogador'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        if (this.$store.state.user.role === 'admin') {
          this.loading = false;
          this.users = (await ServiceUsers.findAll()).data
          this.numeros = (await ServiceNumeros.findAll()).data
        } else {
            this.$router.push({
                name: "home"
            });     
        }    
      },

      formatDate (date) {
          const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
          return new Date(date).toLocaleDateString('pt-PT', options)
      },

      editItem (item) {
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        try {
          /*Apagar*/
          this.$store.dispatch("setSnackbar", {
            color: "green",
            text: "Apagado com sucesso."
          });
          this.users.splice(this.editedIndex, 1)
          this.closeDelete()  
          await ServiceUsers.delete(this.editedItem)
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "red",
            text: "Erro a apagar."
          });
        }
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
        try{
          if (this.$refs.form.validate()) {
            if (this.editedIndex > -1) {
              try {
                /*Editar*/
                this.$store.dispatch("setSnackbar", {
                  color: "green",
                  text: "Editado com sucesso."
                });
                Object.assign(this.users[this.editedIndex], this.editedItem)
                await ServiceUsers.update(this.editedItem)
              } catch (error) {
                this.$store.dispatch("setSnackbar", {
                  color: "red",
                  text: "Erro a editar."
                });
              }
            } else {
              try {
                /*Criar*/
                this.$store.dispatch("setSnackbar", {
                  color: "green",
                  text: "Criado com sucesso."
                });
                this.users.push(this.editedItem)
                await ServiceUsers.create(this.editedItem)
              } catch (error) {
                this.$store.dispatch("setSnackbar", {
                  color: "red",
                  text: "Erro a criar."
                });
              }
            }
            await this.initialize();
            this.close()
          }
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "red",
            text: error
          });
        }
      },

      async savepassword (id) {
        try {
            this.loading = true;
            if (this.passwordNova === this.confirmPassNova) {
              this.$store.dispatch("setSnackbar", {
                color: "green",
                text: "Password modificada."
              });
              await ServiceUsers.changePassword(id, this.passwordNova);
              this.dialog = false
            } else {
              this.$store.dispatch("setSnackbar", {
                color: "red",
                text: "Password´s têm de ser iguais."
              });
            }
            this.loading = false;
        } catch (error) {
          this.loading = false;
          this.$store.dispatch("setSnackbar", {
            color: "red",
            text: error
          });
        }
      }
    },
};
</script>

<style>
.botao_estado_ativo{
  color: green !important;
  font-size: 50px !important;
}
.botao_estado_desativo{
  color: red !important;
  font-size: 50px !important;
}
</style>