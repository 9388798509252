import Api from '@/services/api'

export default {
  findAll() {
    return Api().get('/pagamentos');
  },

  create (item) {
    return Api().post(`pagamentos`, item)
  },

  update (item) {
    return Api().put(`pagamentos/${item.id}`, item)
  },

  delete (item) {
    return Api().delete(`pagamentos/${item.id}`, item)
  },

  findAllUserLogado (userid) {
    return Api().get(`pagamentos/userlogado`, {
      params: {
        userId: userid,
      }
    })
  },

}