import Api from '@/services/api'

export default {
  findAll() {
    return Api().get('/multasjogadores');
  },

  findAllUserLogado (userid) {
    return Api().get(`multasjogadores/userlogado`, {
      params: {
        userId: userid,
      }
    })
  },

  findAllUserMulta (userid, datainiciomes, datafimmes) {
    return Api().get(`multasjogadores/multasdatas`, {
      params: {
        userId: userid,
        datainicio: datainiciomes,
        datafim: datafimmes
      }
    })
  },

  findAllUserMultaData (item) {
    return Api().get(`multasjogadores/multadata`, {
      params: {
        userId: item.userId,
        start: item.start
      }
    }) 
  },

  findAllUserMultasMes (userid, datainiciomes, datafimmes) {
    return Api().get(`multasjogadores/multasmes`, {
      params: {
        userId: userid,
        datainicio: datainiciomes,
        datafim: datafimmes
      }
    })
  },

  findAllValoresMultas () {
    return Api().get(`multasjogadores/valoresmultas`, {
    })
  },

  
  create (item) {
    return Api().post(`multasjogadores`, item)
  },

  update (item) {
    return Api().put(`multasjogadores/${item.id}`, item)
  },

  delete (item) {
    return Api().delete(`multasjogadores/${item.id}`, item)
  },

}