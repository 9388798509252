import Api from '@/services/api'

export default {
  findAll() {
    return Api().get('/multas');
  },

  create (item) {
    return Api().post(`multas`, item)
  },

  update (item) {
    return Api().put(`multas/${item.id}`, item)
  },

  delete (item) {
    return Api().delete(`multas/${item.id}`, item)
  },

  findAllContinued() {
    return Api().get('/multascontinued');
  },
  
}