import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import { sync } from 'vuex-router-sync'
import store from '@/store/store'
import vueDebounce from 'vue-debounce'
import { router } from './router'

Vue.use(vueDebounce, {
  defaultTime: '500ms'
})

Vue.config.productionTip = false

sync(store, router);



new Vue({
  store,
  router,
  vuetify,
  render: h => h(App),
  template: '<App/>'
}).$mount('#app')
