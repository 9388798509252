<template>
  <v-card height="100%">
    <v-speed-dial v-if="$store.state.user.role === 'admin'" fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
        <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark large fab>
                <v-icon v-if="fab">mdi-close</v-icon>
                <v-icon v-else>mdi-filter-variant</v-icon>
            </v-btn>
        </template>
        <v-btn fab dark large color="green" @click="dialog = true">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
    </v-speed-dial>
    <template>
      <div class="text-center">
          <b><p>Multas</p></b>
      </div>
      <v-card class="mb-1">
          <template>
              <v-text-field v-model="search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined" hide-details single-line ></v-text-field>
          </template>
      </v-card>
      <v-data-table :search="search" :loading="loading" :headers="getTableHeaders()" :items="multas" class="elevation-1" :items-per-page="-1">
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="1500px">
                <v-card>
                    <v-tabs v-model="tab" flat dark background-color="primary">
                        <v-tab href="#tab-1">{{ formTitle }}</v-tab>
                        <v-tabs-items v-model="tab">
                            <v-tab-item value="tab-1">
                                <v-card-text>
                                  <v-form ref="form" v-model="valid" lazy-validation>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12">
                                                Estado
                                                <v-switch color="green" v-model="editedItem.discontinued"></v-switch>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field v-model="editedItem.numero" type="number" :rules="[rules.required, rules.isValidNumber]" label="Número"></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field v-model="editedItem.descricao" :rules="[rules.required]" label="Descrição"></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field v-model="editedItem.valor" type="number" :rules="[rules.required, rules.isValidNumber]" label="Valor"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                  </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn color="primary" @click="close">
                                        Cancelar
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" :disabled="!valid" @click="save">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-tabs>
                    
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="1500px">
                <v-card>
                  <v-tabs v-model="tab" flat dark background-color="primary">
                      <v-tab href="#tab-1">Apagar multa</v-tab>
                      <v-tabs-items v-model="tab">
                          <v-tab-item value="tab-1">
                              <v-card-text>
                                  <v-container>
                                      <v-row>
                                          <v-col cols="12">
                                            <v-card-title class="text-h5">Quer mesmo eliminar a multa?</v-card-title>
                                          </v-col>
                                      </v-row>
                                  </v-container>
                              </v-card-text>
                              <v-card-actions>
                                  <v-btn color="primary" @click="closeDelete">
                                      Cancelar
                                  </v-btn>
                                  <v-spacer></v-spacer>
                                  <v-btn color="primary" @click="deleteItemConfirm">
                                      OK
                                  </v-btn>
                              </v-card-actions>
                          </v-tab-item>
                      </v-tabs-items>
                  </v-tabs>                         
                </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.valor`]="{ item }">
              {{ item.valor }} €
          </template>
          <template v-slot:[`item.discontinued`]="{ item }">
              <template v-if="item.discontinued != true">         
                  <v-icon class="botao_estado_desativo">mdi mdi-toggle-switch-off</v-icon>
              </template>
              <template v-else>
                  <v-icon class="botao_estado_ativo">mdi mdi-toggle-switch</v-icon>
              </template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
              <v-icon big class="mr-2" @click="editItem(item)" :color="'green'">
                  mdi-pencil
              </v-icon>
              <v-icon big @click="deleteItem(item)" :color="'red'">
                  mdi-delete
              </v-icon>
          </template>
      </v-data-table>
    </template>
  </v-card>     
</template>
  
<script>
import ServiceMultas from '@/services/ServiceMultas'
export default {
  name: "ListMultas",
  data: () => ({
      dialog: false,
      dialogDelete: false,
      loading: true,
      tab: null,
      search: '',
      headers: [
        { text: 'Número da multa', value: 'numero'},
        { text: 'Descrição', value: 'descricao'},
        { text: 'Valor (€)', value: 'valor'},
        { text: 'Estado', value: 'discontinued'},
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      valid: true,
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      },
      fab: false,
      multas: [],
      editedIndex: -1,
      editedItem: {
        numero: '',
        descricao: '',
        valor: '0.00',
        discontinued: 1,
      },
      defaultItem: {
        numero: '',
        descricao: '',
        valor: '0.00',
        discontinued: 1,
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Criar multa' : 'Editar multa'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        this.loading = false;
        this.multas = (await ServiceMultas.findAll()).data
      },

      editItem (item) {
        this.editedIndex = this.multas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.multas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        try {
          /*Apagar*/
          this.$store.dispatch("setSnackbar", {
            color: "green",
            text: "Apagado com sucesso."
          });
          this.multas.splice(this.editedIndex, 1)
          this.closeDelete()  
          await ServiceMultas.delete(this.editedItem)
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "red",
            text: "Erro a apagar."
          });
        }
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
        try{
          if (this.$refs.form.validate()) {
            if (this.editedIndex > -1) {
              try {
                /*Editar*/
                this.$store.dispatch("setSnackbar", {
                  color: "green",
                  text: "Editado com sucesso."
                });
                Object.assign(this.multas[this.editedIndex], this.editedItem)
                await ServiceMultas.update(this.editedItem)
              } catch (error) {
                this.$store.dispatch("setSnackbar", {
                  color: "red",
                  text: "Erro a editar."
                });
              }
            } else {
              try {
                /*Criar*/
                this.$store.dispatch("setSnackbar", {
                  color: "green",
                  text: "Criado com sucesso."
                });
                this.multas.push(this.editedItem)
                await ServiceMultas.create(this.editedItem)
              } catch (error) {
                this.$store.dispatch("setSnackbar", {
                  color: "red",
                  text: "Erro a criar."
                });
              }
            }
            this.close()
          }
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "red",
            text: error
          });
        }
      },

      getTableHeaders() {
        return this.$store.state.user.role === 'admin'
          ? this.headers
          : this.headers.filter(header => header.value !== 'actions' && header.value !== 'discontinued');
      },
    }
};
</script>

<style>
</style>