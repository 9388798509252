<template>
    <v-card height="100%">
        <v-list three-line>
            <div class="text-center">
                <b><p>Últimas multas adicionadas</p></b>
            </div>
            <template v-for="multa in multasjogadores" >
                <div :key="multa.id">
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>{{ formatDate(multa.start) }} - {{ multa.multa.valor }} €</v-list-item-title>
                            <v-list-item-subtitle>{{ multa.multa.numero }} - {{ multa.multa.descricao }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </template>
        </v-list>
    </v-card>


</template>

<script>
import ServiceMultasJogadores from '@/services/ServiceMultasJogadores'
export default {
  name: "ListCalendarioUser",
  data: () => ({
        multasjogadores: [],
    }),

    computed: {
    },

    watch: {
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        this.loading = false;
        this.multasjogadores = (await ServiceMultasJogadores.findAllUserLogado(this.$store.state.user.id)).data
      },

      formatDate (date) {
          const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
          return new Date(date).toLocaleDateString('pt-PT', options)
      },
    }
};
</script>

<style>
.v-calendar .v-event {
    width: 100% !important;
}
.theme--light.v-calendar-weekly .v-calendar-weekly__day.v-outside {
    background-color: #adacac59;
}
</style>