<template>
  <v-card height="100%">
      <v-data-table :loading="loading" hide-default-footer :headers="headers" :items="users" class="elevation-1" :items-per-page="-1">
          <template v-slot:[`item.numero`]="{ item }">
            {{ item.numero?.numero }}
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:[`item.valornumero`]="{ item }">
            {{ item.numero?.valor}} €
          </template>
          <template v-slot:[`item.agosto`]="{ item }">
            {{ somaValoresMes(item.id, 8, 2024) }} €
          </template>
          <template v-slot:[`item.setembro`]="{ item }">
            {{ somaValoresMes(item.id, 9, 2024) }} €
          </template>
          <template v-slot:[`item.outubro`]="{ item }">
            {{ somaValoresMes(item.id, 10, 2024) }} €
          </template>
          <template v-slot:[`item.novembro`]="{ item }">
            {{ somaValoresMes(item.id, 11, 2024) }} €
          </template>
          <template v-slot:[`item.dezembro`]="{ item }">
            {{ somaValoresMes(item.id, 12, 2024) }} €
          </template>
          <template v-slot:[`item.janeiro`]="{ item }">
            {{ somaValoresMes(item.id, 1, 2025) }} €
          </template>
          <template v-slot:[`item.fevereiro`]="{ item }">
            {{ somaValoresMes(item.id, 2, 2025) }} €
          </template>
          <template v-slot:[`item.marco`]="{ item }">
            {{ somaValoresMes(item.id, 3, 2025) }} €
          </template>
          <template v-slot:[`item.abril`]="{ item }">
            {{ somaValoresMes(item.id, 4, 2025) }} €
          </template>
          <template v-slot:[`item.maio`]="{ item }">
            {{ somaValoresMes(item.id, 5, 2025) }} €
          </template>
          <template v-slot:[`item.junho`]="{ item }">
            {{ somaValoresMes(item.id, 6, 2025) }} €
          </template>
          <template v-slot:[`item.julho`]="{ item }">
            {{ somaValoresMes(item.id, 7, 2025) }} €
          </template>
          <template v-slot:[`item.valortotal`]="{ item }">
            <b>{{ somaValoresTotal(item.id) }} €</b>
          </template>
          <template v-slot:[`item.valorpago`]="{ item }">
            <b>{{ somaValoresPago(item.id) }} €</b>
          </template>
          <template v-slot:[`item.valoremdivida`]="{ item }">
            <template v-if="somaValoresEmDivida(item.id) < 0">
                <b class="Tred">{{ somaValoresEmDivida(item.id) }} €</b>
            </template>
            <template v-else>
                <b class="Tgreen">{{ somaValoresEmDivida(item.id) }} €</b>
            </template>
          </template>
      </v-data-table>
      <v-data-table :loading="loading" hide-default-footer :headers="headers2" :items="totais" class="elevation-1" :items-per-page="1" id="tabelatotais">
          <template v-slot:[`item.numero`]>
            <b>Total das multas</b>
          </template>
          <template v-slot:[`item.name`]>
          </template>
          <template v-slot:[`item.valornumero`]>
            <b>{{ somaValoresTotalNumeros.toFixed(2) }} €</b>
          </template>
          <template v-slot:[`item.agosto`]>
            <b>{{ somaValoresTotalMes(8, 2024) }} €</b>
          </template>
          <template v-slot:[`item.setembro`]>
            <b>{{ somaValoresTotalMes(9, 2024) }} €</b>
          </template>
          <template v-slot:[`item.outubro`]>
            <b>{{ somaValoresTotalMes(10, 2024) }} €</b>
          </template>
          <template v-slot:[`item.novembro`]>
            <b>{{ somaValoresTotalMes(11, 2024) }} €</b>
          </template>
          <template v-slot:[`item.dezembro`]>
            <b>{{ somaValoresTotalMes(12, 2024) }} €</b>
          </template>
          <template v-slot:[`item.janeiro`]>
            <b>{{ somaValoresTotalMes(1, 2025) }} €</b>
          </template>
          <template v-slot:[`item.fevereiro`]>
            <b>{{ somaValoresTotalMes(2, 2025) }} €</b>
          </template>
          <template v-slot:[`item.marco`]>
            <b>{{ somaValoresTotalMes(3, 2025) }} €</b>
          </template>
          <template v-slot:[`item.abril`]>
            <b>{{ somaValoresTotalMes(4, 2025) }} €</b>
          </template>
          <template v-slot:[`item.maio`]>
            <b>{{ somaValoresTotalMes(5, 2025) }} €</b>
          </template>
          <template v-slot:[`item.junho`]>
            <b>{{ somaValoresTotalMes(6, 2025) }} €</b>
          </template>
          <template v-slot:[`item.julho`]>
            <b>{{ somaValoresTotalMes(7, 2025) }} €</b>
          </template>     
          <template v-slot:[`item.valortotal`]>
            <b>{{ somaValoresTotall.toFixed(2) }} €</b>
          </template>
          <template v-slot:[`item.valorpago`]>
            <b>{{ somaValoresTotalPago.toFixed(2) }} €</b>
          </template>
          <template v-slot:[`item.valoremdivida`]>
            <template v-if="somaValoresTotalEmDivida < 0">
                <b class="Tred">{{ somaValoresTotalEmDivida.toFixed(2) }} €</b>
            </template>
            <template v-else>
                <b class="Tgreen">{{ somaValoresTotalEmDivida.toFixed(2) }} €</b>
            </template>
          </template>
      </v-data-table>
  </v-card>
</template>
  
<script>
import ServiceUsers from '@/services/ServiceUsers'
import ServicePagamentos from '@/services/ServicePagamentos'
import ServiceMultasJogadores from '@/services/ServiceMultasJogadores'
import ServiceNumeros from '@/services/ServiceNumeros'
export default {
  name: "ListCalendarioUsers",
  data: () => ({
        loading: true,
        headers: [
            { text: 'Número do Jogador', value: 'numero' },
            { text: 'Nome do Jogador', value: 'name' },
            { text: 'Valor Número (€)', value: 'valornumero' },
            { text: 'Agosto 2024 (€)', value: 'agosto' },
            { text: 'Setembro 2024 (€)', value: 'setembro' },
            { text: 'Outubro 2024 (€)', value: 'outubro' },
            { text: 'Novembro 2024 (€)', value: 'novembro' },
            { text: 'Dezembro 2024 (€)', value: 'dezembro' },
            { text: 'Janeiro 2025 (€)', value: 'janeiro' },
            { text: 'Fevereiro 2025 (€)', value: 'fevereiro' },
            { text: 'Março 2025 (€)', value: 'marco' },
            { text: 'Abril 2025 (€)', value: 'abril' },
            { text: 'Maio 2025 (€)', value: 'maio' },
            { text: 'Junho 2025 (€)', value: 'junho' },
            { text: 'Julho 2025 (€)', value: 'julho' },
            { text: 'Total (€)', value: 'valortotal' },
            { text: 'Pago (€)', value: 'valorpago' },
            { text: 'Em divida (€)', value: 'valoremdivida' },
        ],
        headers2: [
            { text: 'Número do Jogador', value: 'numero' },
            { text: 'Nome do Jogador', value: 'name' },
            { text: 'Valor Número (€)', value: 'valornumero' },
            { text: 'Agosto 2024 (€)', value: 'agosto' },
            { text: 'Setembro 2024 (€)', value: 'setembro' },
            { text: 'Outubro 2024 (€)', value: 'outubro' },
            { text: 'Novembro 2024 (€)', value: 'novembro' },
            { text: 'Dezembro 2024 (€)', value: 'dezembro' },
            { text: 'Janeiro 2025 (€)', value: 'janeiro' },
            { text: 'Fevereiro 2025 (€)', value: 'fevereiro' },
            { text: 'Março 2025 (€)', value: 'marco' },
            { text: 'Abril 2025 (€)', value: 'abril' },
            { text: 'Maio 2025(€)', value: 'maio' },
            { text: 'Junho 2025 (€)', value: 'junho' },
            { text: 'Julho 2025 (€)', value: 'julho' },
            { text: 'Total (€)', value: 'valortotal' },
            { text: 'Pago (€)', value: 'valorpago' },
            { text: 'Em divida (€)', value: 'valoremdivida' },
        ],
        somaValoresTotalNumeros: 0,
        somaValoresTotall: 0,
        somaValoresTotalPago: 0,
        somaValoresTotalEmDivida: 0,
        users: [],
        multastodas: [],
        numeros: [],
        totais: [
          {
          }
        ],
    }),

    computed: {
      
    },

    watch: {
      
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        this.loading = false;
        this.users = (await ServiceUsers.findAllNaoAdmin()).data
        this.multastodas = (await ServiceMultasJogadores.findAllValoresMultas()).data
        this.numeros = (await ServiceNumeros.findAll()).data
        this.pagamentos = (await ServicePagamentos.findAll()).data
        for (let i = 0; i < this.users.length; i++) {
            this.somaValoresTotalNumeros = this.somaValoresTotalNumeros + parseFloat(this.users[i].numero?.valor)
            
        }
        for (let i = 0; i < this.multastodas.length; i++) {
            this.somaValoresTotall = this.somaValoresTotall + parseFloat(this.multastodas[i].multa?.valor)
        }
        for (let i = 0; i < this.pagamentos.length; i++) {
          this.somaValoresTotalPago = this.somaValoresTotalPago + parseFloat(this.pagamentos[i].valor)
        }
        this.somaValoresTotall = this.somaValoresTotall + this.somaValoresTotalNumeros
        this.somaValoresTotalEmDivida = this.somaValoresTotalPago - this.somaValoresTotall
      },
      getMonth(dateString) {
        const dateObject = new Date(dateString);
        return dateObject.getMonth() + 1;
      },
      getYear(dateString) {
        const dateObject = new Date(dateString);
        return dateObject.getFullYear();
      },

      //feito
      somaValoresMes(userId, month, year) {
        const multasMes = this.multastodas.filter(
            multa => multa.userId === userId && this.getMonth(multa.start) === month && this.getYear(multa.start) === year
        );

        const soma = multasMes.reduce((total, multa) => total + parseFloat(multa.multa?.valor || 0), 0);
        
        return soma.toFixed(2);
      },

      //feito
      somaValoresTotal(userId) {
        const users = this.users.filter(
            user => user.id === userId
        );
        const multasMes = this.multastodas.filter(
            multa => multa.userId === userId
        );

        const somames = multasMes.reduce((total, multa) => total + parseFloat(multa.multa?.valor || 0), 0)
        const somanumerovalor = users.reduce((total, user) => total + parseFloat(user.numero?.valor|| 0), 0)
        const soma = somames + somanumerovalor

        return soma.toFixed(2);
      },

      //feito
      somaValoresPago(userId) {
        const users = this.users.find(
          user => user.id === userId
        );

        const somapago = users.pagamentos.reduce((total, pagamento) => total + parseFloat(pagamento.valor || 0), 0);

        return somapago.toFixed(2); 
        
      },

      //feito
      somaValoresEmDivida(userId) {
        const users = this.users.filter(
            user => user.id === userId
        );
        const multasMes = this.multastodas.filter(
            multa => multa.userId === userId
        );

        const userspagamentos = this.users.find(
          userr => userr.id === userId
        );

        const somames = multasMes.reduce((total, multa) => total + parseFloat(multa.multa?.valor || 0), 0)
        const somanumerovalor = users.reduce((total, user) => total + parseFloat(user.numero?.valor|| 0), 0)
        const somapago = userspagamentos.pagamentos.reduce((total, pagamento) => total + parseFloat(pagamento.valor || 0), 0);

        const soma = (somapago) - (somames + somanumerovalor)

        return soma.toFixed(2);
      },

      somaValoresTotalMes(month, year) {
        const multasMes = this.multastodas.filter(
            multa => this.getMonth(multa.start) === month && this.getYear(multa.start) === year
        );

        const soma = multasMes.reduce((total, multa) => total + parseFloat(multa.multa?.valor || 0), 0);
        return soma.toFixed(2);
      },
    }
};
</script>

<style>
#tabelatotais thead {
  background-color: #d21919 !important;
}
</style>