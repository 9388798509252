<template>
    <v-card height="100%">
        <v-toolbar flat dark color="primary" class="elevation-0">
            <v-btn fab text big color="white darken-2" @click="prev">
                <v-icon big>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab text big color="white darken-2" @click="next">
                <v-icon big>mdi-chevron-right</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <span>{{valorTotalMes.toFixed(2)}}€</span>
        </v-toolbar>
        
        <v-calendar locale="pt-pt" ref="calendar" v-model="focus" color="primary" :events="multasjogadores" :type="type" id="calendarioUser">
            <template v-slot:event="{ event }">
                <div @click="eventsModal(event)">
                    <span>&nbsp;</span>
                </div>
            </template>
        </v-calendar>
        <v-dialog v-model="dialog" max-width="1500px">
            <v-card>
                <v-tabs v-model="tab" flat dark background-color="primary">
                    <v-tab href="#tab-1">Multas do dia selecionado</v-tab>
                    <v-tabs-items v-model="tab">
                        <v-tab-item value="tab-1">
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-data-table :loading="loading" hide-default-footer :headers="headers" :items="multasjogadoresdata" class="elevation-1" :items-per-page="-1">
                                                <template v-slot:[`item.numero`]="{ item }">
                                                    {{ item.multa?.numero }}
                                                </template>
                                                <template v-slot:[`item.descricao`]="{ item }">
                                                    {{ item.multa?.descricao }}
                                                </template>
                                                <template v-slot:[`item.valor`]="{ item }">
                                                    {{ item.multa?.valor }} €
                                                </template>
                                            </v-data-table>
                                        </v-col>
                                        <v-col cols="12">
                                            <span class="totaldia"><b>Total do dia: </b></span><span class="totaldia">{{ valorTotalDia.toFixed(2) }}€</span>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="close">
                                    Fechar
                                </v-btn>
                            </v-card-actions>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-card>
        </v-dialog>
    </v-card>


</template>

<script>
import ServiceMultasJogadores from '@/services/ServiceMultasJogadores'
export default {
  name: "ListCalendarioUser",
  data: () => ({
        valorTotalDia: 0,
        valorTotalMes: 0,
        diainiciomes: "",
        diafimmes: "",
        dialog: false,
        tab: null,
        focus: '',
        loading: true,
        type: 'month',
        typeToLabel: {
            month: 'Month',
            week: 'Week',
            day: 'Day'
        },
        events: [],
        multasjogadores: [],
        multasjogadoresdata: [],
        multasjogadoresmes: [],
        headers: [
        { text: 'Número', value: 'numero', align: 'start', sortable: false },
        { text: 'Multa', value: 'descricao', sortable: false },
        { text: 'Valor (€)', value: 'valor', sortable: false },
      ],
    }),

    async mounted () {  
        this.$refs.calendar.checkChange()
        this.mesqueestamos()
    },

    computed: {
      
    },

    watch: {
        dialog (val) {
            val || this.close()
        },
        
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        this.loading = false;
      },
      setToday () {
        this.focus = ''
      },

      async prev () {
        await this.$refs.calendar.prev()
        this.$nextTick(() => {
            this.mesqueestamos();
        });
      },

      async next () {
        await this.$refs.calendar.next()
        this.$nextTick(() => {
            this.mesqueestamos();
        });
      },

      close () {
        this.dialog = false
      },

      async eventsModal (event) {
        this.valorTotalDia = 0
        this.dialog = true
        this.multasjogadoresdata = (await ServiceMultasJogadores.findAllUserMultaData(event)).data
        for (let i = 0; i < this.multasjogadoresdata.length; i++) {
            this.valorTotalDia = this.valorTotalDia + parseFloat(this.multasjogadoresdata[i].multa.valor);
        }
      },
      async mesqueestamos () {
        this.valorTotalMes = 0
        this.diainiciomes = this.$refs.calendar.$data.lastStart.year + "-" + this.$refs.calendar.$data.lastStart.month + "-" + this.$refs.calendar.$data.lastStart.day
        this.diafimmes = this.$refs.calendar.$data.lastEnd.year + "-" + this.$refs.calendar.$data.lastEnd.month + "-" + this.$refs.calendar.$data.lastEnd.day
        this.multasjogadores = (await ServiceMultasJogadores.findAllUserMulta(this.$store.state.user.id, this.diainiciomes, this.diafimmes)).data
        this.multasjogadoresmes = (await ServiceMultasJogadores.findAllUserMultasMes(this.$store.state.user.id, this.diainiciomes, this.diafimmes)).data
        for (let i = 0; i < this.multasjogadoresmes.length; i++) {
            this.valorTotalMes = this.valorTotalMes + parseFloat(this.multasjogadoresmes[i].multa.valor);
        }
      }
    }
};
</script>

<style>
.v-calendar .v-event {
    width: 100% !important;
}
.theme--light.v-calendar-weekly .v-calendar-weekly__day.v-outside {
    background-color: #adacac59;
}
</style>